import Category from "../../Shop/Category";

const Shop = () => {
  return (
    <>
      <Category />
    </>
  );
};

export default Shop;
