import React from "react";
import Product from "../../Shop/Product";

function Home() {
  return (
    <>
      <Product />
    </>
  );
}

export default Home;
